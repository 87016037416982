body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  body {-webkit-print-color-adjust: exact;}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {display:none;}

.cover-gradient {
  -webkit-mask-image: linear-gradient(black 80%, transparent);
}

.carousel-gradient {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
  -webkit-mask-image: linear-gradient(180deg, rgba(0,0,0,1) 75%, rgba(0,0,0,0.95) 85%, rgba(0,0,0,0.70) 90%, rgba(0,0,0,0.4) 95%, rgba(255,255,255,0.1) 100%);
}

.carousel-gradient-mobile {
  object-fit: cover;
  height: 93vh;
  width: 100vw;
  -webkit-mask-image: linear-gradient(163deg, rgba(0,0,0,1) 80%, rgba(0,0,0,1) 80%, rgba(255,255,255,0) 102%);
}

#tsparticles {
  height: 100vh;
  width: -webkit-fill-available;
}
